import React from 'react';

import Layout from 'components/Layout';
import Section from 'components/Section';
import OfferRange from 'components/OfferRange';

const title =
  'Motoroma | Oferta - naprawa motocykli, quad, mechanika, sprzedaż części, wulkanizacja';
const description =
  'Świadczymy usługi kompleksowej diagnostyki wszystkich marek a w szczególności BMW), naprawy (w tym powypadkowe), konserwacji i przygotowania do sezonu.';

const OfferPage = () => (
  <Layout
    smallHero
    title="Oferta"
    slug="/offer"
    metaTitle={title}
    metaDescription={description}
  >
    <Section withoutHeader $noButton>
      <OfferRange stacked />
    </Section>
  </Layout>
);

export default OfferPage;
